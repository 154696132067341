import React from "react";
import { NavHashLink } from "react-router-hash-link";

import styles from "./styles.module.scss";
import { images } from "../../../core/constants/images";
import DownloadButton from "../../components/DownloadButton";
import { Text } from "../../../core/constants/strings";

const Welcome = () => {
  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${images.welcomeBackground})` }}>
      <div>
        <div className={styles.logoBar}>
          <img className={styles.logoImage} src={images.logo} />
          <h1 className={styles.logoText}>{Text.welcome.logoText}</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.contentText}>
            <h1 className={styles.contentTitle}>{Text.welcome.content.title}</h1>
            <p className={styles.contentParagraph}>{Text.welcome.content.paragraph}</p>
            <div className={styles.buttons}>
              <NavHashLink to={"#video"} smooth>
                <button className={styles.seeButton}>{Text.welcome.buttons.seeButton}</button>
              </NavHashLink>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.imageWrapper}>
              <img className={styles.contentImage} src={images.iphone} />
            </div>
            <div className={styles.downloadButtons}>
              <div className={styles.downlaodApple}>
                <DownloadButton
                  storeString={Text.downloadLinks.apple}
                  source={images.appleLogo}
                  text={Text.welcome.buttons.appleButton.text}
                  title={Text.welcome.buttons.appleButton.title}
                />
              </div>
              <div className={styles.DownloadGoogle}>
                <DownloadButton
                  storeString={Text.downloadLinks.google}
                  source={images.googlePlayLogo}
                  text={Text.welcome.buttons.googlePlayButton.text}
                  title={Text.welcome.buttons.googlePlayButton.title}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
